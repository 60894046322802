.zero-margin {
  margin-bottom: 0px;
}

.founder-name {
  font-size: 1.2rem !important;
  font-weight: 600;
  color: white;
}

/* for smaller screens */
@media screen and (max-width: 670px) {
  .founder-name {
    font-size: 1.1rem !important;
    font-weight: 400;
    color: white;
  }
}
