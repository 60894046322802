header {
  /* background-image: "url(/img/lession.jpg)" no-repeat; */
  background-size: cover;
  background-position: center;
  min-height: 1000px;
}

@media screen and (max-width: 670px) {
  header {
    min-height: 500px;
  }
}

.btn.waves-effect.waves-light {
  border-radius: 10px;
}

.btn.waves-effect.waves-light.width100 {
  width: 100%;
}

.carousel {
  /* width: 50% !important; */
  height: 330px !important;
}

.carousel-item {
  min-height: 330px !important;
  padding: 20px !important;
}
.carousel-item-text {
  font-size: 1.2rem !important;
  /* font-family: "Itim", cursive; */
}
.carousel-name-text {
  font-size: 1.2rem !important;
  font-weight: 600;
}
.footer-header-text {
  font-size: 1.7rem;
  font-weight: 400;
}

/* .card-image-summary {
  background-color: grey;
  height: 83px;
}

.card-image-details {
  background-color: grey;
  height: 260px;
} */

/* for smaller screens */
@media screen and (max-width: 670px) {
  .carousel-item-text {
    font-size: 1rem !important;
    font-weight: 400;
  }
  .carousel-name-text {
    font-size: 1.1rem !important;
  }
  h3 {
    font-size: 2.7rem !important;
  }
}
a {
  word-break: break-all;
}

.text-bold {
  font-weight: 600;
}

.text-tiny {
  font-size: 0.5em;
  /* color: #26a69a; */
}

.text-medium {
  font-size: 2em;
}

.margin-top-10 {
  margin-top: 10px;
}

.active-menu {
  color: #1de9b6;
  border-bottom: #1de9b6 solid 2px;
}

.session-title-summary {
  font-size: 1.5em !important;
  padding: 0px !important;
  padding-top: 5px !important;
  padding-left: 24px !important;
  word-break: break-word;
}

.session-title-detail {
  font-size: 3.5em !important;
  padding: 5px !important;
  padding-left: 24px !important;
  word-break: break-word;
}

.width-85 {
  width: 85%;
}

@media only screen and (max-width: 520px) {
  .session-title-detail {
    font-size: 1.8em !important;
    font-weight: 300 !important;
    padding: 0px !important;
    padding-top: 5px !important;
    padding-left: 24px !important;
    word-break: break-word;
  }
}

.notification {
  line-height: 65px !important;
}

/* Tooltip container */
.tooltip {
  /* position: relative; */
  display: inline-block;
  /*border-bottom: 1px dotted black; */ /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  font-size: 0.8rem;
  width: 200px;
  text-align: left;
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

/* to make horizontal and vertical center */
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.thumbs i:hover {
  color: #2196f3;
  cursor: pointer;
}

.thumb-selected {
  color: #2196f3;
}

i.left {
  margin-left: 10px;
}

.student-type {
  height: 37px;
  width: 37px;
  padding-left: 5px;
  margin-left: 5px;
  border: 1px solid #80cbc4;
  background-color: #b2dfdb;
  border-radius: 50%;
}
