.padding-top-30 {
  padding-top: 30px;
}

.btn-floating.btn-large {
  width: 135px !important;
  height: 135px !important;
  border: 3px solid white;
}

.text-middle {
  margin-top: 3.1rem !important;
}
